<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <div class="flex">
            <div class="flex-grow">
                <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">{{ $t('tickets.title') }}</h2>
            </div>
            <div class="flex-none">
                <Toggle @changed="toggleView">
                </Toggle>
            </div>
        </div>
        <Schedule v-if="view === 'FUTURE'">
        </Schedule>
        <History v-if="view === 'HISTORY'">
        </History>        
    </div>
</template>
       
<script>
import History from '../components/History.vue'
import Schedule from '../components/Schedule.vue'
import Toggle from '../components/Toggle.vue'
export default {
  name: 'Ticketmatic',
  title: 'Mijn Tickets',
  components: {
      Toggle,
      History,
      Schedule,
  },
  data() {
    return {
        view: "FUTURE",
      }
  },
  methods: {
      toggleView(newView) {
          this.view = newView;
      }
  }
}
</script>