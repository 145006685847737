<template>
    <div>
        <template v-if="showContent">
            <Event v-for="(event) of localEvents" v-bind="event" v-bind:key="event.eventid" v-bind:nbroftickets="event.tickets.length">
            </Event>
            <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
                <div slot="spinner">
                    <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
                </div>
                <div class="text-left" slot="no-more"></div>
                <div class="text-left" slot="no-results">{{ $t('history.noTickets') }}</div>
            </infinite-loading>
        </template>
        <div v-if="error">
            <p>
                {{ $t('error.fetchingData') }}
            </p>
            <p>
                {{ $t('history.tryAgainOrContact') }} 
                <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> 
                <span v-if="config.phone">
                {{ $t('history.or') }} {{ config.phone }}
                </span>.
            </p>
        </div>  
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';
import Event from '../components/Event.vue'
const moment = require("moment")
const R = require("ramda")
moment.locale("nl")

const STEPSIZE = 5

export default {
  name: 'Ticketmatic',
  title: 'history.title',
  components: {
      Event,
      InfiniteLoading,
  },
  data() {
    return {
        localEvents: [],
        counter: 0,
        error: false,
    }
  },
  computed: {
    ...mapGetters(["config", "history"]),
    showContent: function() {
        return (!this.error)
    }
  },
  methods: {
    reset() {
        this.initList()
        this.$store.dispatch('resetHistory')
    },
    initList: function () {
        this.counter = 0;
        this.localEvents = [];
    },
    toggleList: function () {
        this.initList();
    },
    refreshHistory: async function () {
        await this.$store.dispatch("setHistory");
    },
    infiniteHandler: function($state) {
        setTimeout(() => {
            if (this.history.events === null) {
                return
            }
            const items = this.history.events.slice(this.counter, this.counter + STEPSIZE)
            this.localEvents.push(...items);
            this.counter += STEPSIZE;
            if (items.length > 0) {
                $state.loaded();
            }
            if (this.localEvents.length === this.history.events.length) {
                $state.complete();
            }
        }, 500);
    },
  },
  filters: {
    friendlyDate: function (date) {
        return moment(date).format('D MMMM YYYY')
    },
  },
  watch: {
      history: {
          handler(val, oldVal) {
              if (!this.history) {
                  return;
              }
              if (!this.history.events) {
                  return;
              }
              if (R.equals(val, oldVal)) {
                  return;
              }
              this.initList();
              if (this.$refs.InfiniteLoading) {
                  this.$refs.InfiniteLoading.stateChanger.reset();
              }          
          },
          deep: true,
      },
  },  
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "History",
    })

    if (this.history && this.history.events) {
        return; // do not refresh every mount
    }

    try {
        await this.$store.dispatch('setHistory')
    } catch (ex) {
        this.error = true;
        this.$store.dispatch('resetHistory')
    }
  },
}
</script>