<template>
    <div class="g-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
        <button 
            @click="toggle('FUTURE')" 
            v-bind:class="{ active: (state==='FUTURE') }" 
            class="inline-flex items-center transition-colors duration-500 ease-out focus:outline-none hover:text-primary focus:text-primary rounded-l-full px-4 py-2" id="grid">
            <span>{{ $t('current') }}</span>
        </button>
        <button 
            @click="toggle('HISTORY')" 
            v-bind:class="{ active: (state==='HISTORY') }" 
            class="inline-flex items-center transition-colors duration-500 ease-out focus:outline-none hover:text-primary focus:text-primary rounded-r-full px-4 py-2" id="list">
            <span>{{ $t('history') }}</span>
        </button>
    </div>
</template>

<style>
.active {
    @apply bg-primary text-white rounded-full focus:text-white hover:text-white;
}
</style> 

<script>
export default {
  data() {
    return {
        state: "FUTURE",
    }
  },
  i18n: {
    messages: {
        en: { 
            current: 'Current',
            history: 'History'
        },
        nl: {
            current: 'Actueel',
            history: 'Historie' 
        },
    }
  },
  methods: {
      toggle: function(newState) {
          this.state = newState;
          this.$emit('changed', newState);
      },
  },
}
</script>